<template>
  <div>
    <!--begin: Head -->
    <div
      class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <div class="kt-user-card__avatar">
        <img class="kt-hidden" alt="Pic" src="@/assets/media/users/300_25.jpg" />
        <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
        <span class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">{{
          username.charAt(0).toUpperCase()
        }}</span>
      </div>
      <div class="kt-user-card__name">{{ username.charAt(0).toUpperCase() + username.slice(1) }}</div>
    </div>
    <!--end: Head -->
    <!--begin: Navigation -->
    <div class="kt-notification">
      <div class="kt-notification__custom kt-space-between">
        <a @click.prevent="onLogout()" class="btn btn-label btn-label-brand btn-sm btn-bold">Sign Out</a>
      </div>
    </div>
    <!--end: Navigation -->
  </div>
</template>

<script>
import { LOGOUT } from '@/store/auth.module';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'KTDropdownUser',
  methods: {
    ...mapActions({
      logout: 'logout',
    }),
    onLogout() {
      this.logout();
    },
  },
  computed: {
    ...mapGetters({
      username: 'username',
    }),
    backgroundImage() {
      return process.env.BASE_URL + 'assets/media/misc/bg-1.jpg';
    },
  },
};
</script>
